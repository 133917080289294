@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
.Calendar {

    background-color: var(--main-bg-color);

    .month-row-container {
        stroke: #fff;
        fill: none;
    }
    
    .month-container {
        stroke: #fff;
        fill: none;
    }

    .light-stroke {
        stroke: #fff;
    }


    .month-title {
        fill: #fff;
        text-transform: uppercase;
        text-anchor: middle;
        dominant-baseline: middle;
        // font-family: 'Playfair Display SC', serif;
        font-family: 'Raleway', sans-serif;

        font-size: 30px;
    }

    .day-label {
        fill: #fff;
        font-family: 'Raleway', sans-serif;
        text-anchor: middle;
        font-size: 14px;
    }
    .day-label-neg {
        stroke: #fff;
        stroke-width: 0.4px;
        fill: #000;
        font-family: 'Raleway', sans-serif;
        text-anchor: middle;
        font-size: 14px;
    }

    .day-annotation {
        fill: #fff;
        font-weight: normal;
        font-family: 'Raleway', sans-serif;
        text-anchor: middle;       
        font-size: 10px;
    }

    .day-rect {
        fill: none;
        stroke: rgba(255, 255, 255, 0.2);
        stroke-width: 0.5;
    }

    .day-rect-fill{
        fill: rgba(255, 255, 255, 0.15);
    }
    


    .moon-circle {
        fill: #fff;
    }

    .sun-path {
        stroke: #fff;
        fill: none;
        stroke-width: 0.5;
    }
    .sun-path-light {
        stroke: #fff;
        fill: none;
        stroke-width: 0.25;
    }

}
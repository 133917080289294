@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
:root {
  --main-bg-color: #222; }

body {
  margin: 0;
  padding: 0; }

.Calendar {
  background-color: var(--main-bg-color); }
  .Calendar .month-row-container {
    stroke: #fff;
    fill: none; }
  .Calendar .month-container {
    stroke: #fff;
    fill: none; }
  .Calendar .light-stroke {
    stroke: #fff; }
  .Calendar .month-title {
    fill: #fff;
    text-transform: uppercase;
    text-anchor: middle;
    dominant-baseline: middle;
    font-family: 'Raleway', sans-serif;
    font-size: 30px; }
  .Calendar .day-label {
    fill: #fff;
    font-family: 'Raleway', sans-serif;
    text-anchor: middle;
    font-size: 14px; }
  .Calendar .day-label-neg {
    stroke: #fff;
    stroke-width: 0.4px;
    fill: #000;
    font-family: 'Raleway', sans-serif;
    text-anchor: middle;
    font-size: 14px; }
  .Calendar .day-annotation {
    fill: #fff;
    font-weight: normal;
    font-family: 'Raleway', sans-serif;
    text-anchor: middle;
    font-size: 10px; }
  .Calendar .day-rect {
    fill: none;
    stroke: rgba(255, 255, 255, 0.2);
    stroke-width: 0.5; }
  .Calendar .day-rect-fill {
    fill: rgba(255, 255, 255, 0.15); }
  .Calendar .moon-circle {
    fill: #fff; }
  .Calendar .sun-path {
    stroke: #fff;
    fill: none;
    stroke-width: 0.5; }
  .Calendar .sun-path-light {
    stroke: #fff;
    fill: none;
    stroke-width: 0.25; }

